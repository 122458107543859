<template>
    <div class="home">
        <nav class="navTab">
            <div class="isDivcom"><h2>管理员配置</h2></div>
           
        </nav>
        <nav class="nav_sech" v-if="tabIndex==0">
            <div style="width:224px;padding-left:15px">
                 <!--suffix-icon="el-icon-search" -->
                <el-input
                    size="medium "
                    placeholder="请输入姓名/关键字"
                    v-model="keywords">
                    <i slot="suffix" @click="searchName" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
            <el-button @click="addaDmin" size="medium " type="primary" class="el_btn">添加管理员</el-button> 
        </nav>
        <main class="el_table" style="margin: 15px 15px 0" v-if="tabIndex==0">
            <el-table
                :data="tableData"
                style="width: 100%;margin-bottom: 20px"
                align="center"
                height="55vh"
                border>
                <el-table-column
                    prop="username"
                    label="用户名"
                    align="center"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="account"
                    label="账号"
                    align="center"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="email"
                    label="邮件"
                    align="center"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="createtime"
                    label="创建时间"
                    align="center"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="loginutime"
                    label="上次登录时间"
                    align="center"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="loginip"
                    label="登录ip"
                    align="center"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="userType"
                    label="类型"
                    align="center"
                    width="200">
                    <template slot-scope="scope">
                        <span style="color:blue" v-if="scope.row.userType==0">管理员</span>
                        <span style="color:red" v-else-if="scope.row.userType==1">农村劳动力转移就业管理员</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="remark"
                        label="备注"
                        align="center"
                        width="228">
                </el-table-column>
                <el-table-column label="操作" align="center" width="480">
                    <template slot-scope="scope">
                        <el-button
                            size="medium "
                            @click="handleEditrow(scope.$index, scope.row)"
                            type="primary">
                            编辑
                        </el-button>
                        <el-button
                            size="medium "
                            type="warning"
                            @click="handleRoles(scope.$index, scope.row)">
                            关联角色
                        </el-button>
                        <el-button
                            size="medium "
                            :type="scope.row.state==0?'primary':'warning'"
                            @click="handleProhibit(scope.$index, scope.row)">
                            {{scope.row.state==0?'开启':'禁用'}}
                        </el-button>
                       <el-button
                            size="medium "
                            type="danger"
                            @click="handleEditPwd(scope.row.id)">重置密码</el-button>

                        <el-button
                                size="medium "
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div  style="height:60px;text-align: center;">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20, 30, 40, 50]"
                    :page-size="10"
                    :current-page="pages"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="currenttotal">
                </el-pagination>
            </div>
        </main>
        <!--新增弹出框-->
        <el-dialog
                title="新增管理员"
                :visible.sync="dialogAdd"
                width="38%">
                <template>
                    <el-form ref="permisAdd" size="medium " :model="permisAdd" label-width="70px">
                        <el-form-item label="用户名">
                            <el-input v-model="permisAdd.username" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="账号">
                            <el-input v-model="permisAdd.account" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item label="邮件">
                            <el-input v-model="permisAdd.email" placeholder="请输入邮件"></el-input>
                        </el-form-item>
                         <el-form-item label="备注">
                            <el-input maxlength="30" v-model="permisAdd.remark" placeholder="请输入备注/30字内"></el-input>
                        </el-form-item>
                        <el-form-item label="类型">
                            <el-select class="el_input" v-model="permisAdd.userType" placeholder="请选择类型" clearable>
                                <el-option label="管理员" value="0"></el-option>
                                <el-option label="农村劳动力转移就业管理员" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input show-password type="password" v-model="permisAdd.pwd" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码">
                            <el-input show-password type="password" v-model="permisAdd.pwds" placeholder="请确认密码"></el-input>
                        </el-form-item>
                    </el-form>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogAdd = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData">提 交</el-button>
                </span>
        </el-dialog>
        <!--编辑弹出框-->
        <el-dialog
            title="编辑"
            :visible.sync="dialogEdit"
            width="38%">
            <template>
                <el-form ref="permisEdit" size="medium " :model="permisEdit" label-width="70px">
                    <el-form-item label="用户名">
                        <el-input v-model="permisEdit.username" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="邮件名">
                        <el-input v-model="permisEdit.email" placeholder="请输入邮件名"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input maxlength="30" v-model="permisEdit.remark" placeholder="请输入备注/30字内"></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select class="el_input" v-model="permisEdit.userType" placeholder="请选择类型" clearable>
                            <el-option label="管理员" value="0"></el-option>
                            <el-option label="农村劳动力转移就业管理员" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogEdit = false">取 消</el-button>
                <el-button type="primary" @click="postEditData">提 交</el-button>
            </span>
        </el-dialog>
        <!--分配角色弹出框-->
        <el-dialog
            title="分配角色"
            :visible.sync="dialogRole"
            width="38%">
            <template>
                <el-table
				    ref="giveRolemultipleTable"
				    :data="roleData"
				    tooltip-effect="dark"
				    style="width: 100%"
				    @selection-change="handleGiveSelectionChange">
				    <el-table-column
				      type="selection"
				      width="55">
				    </el-table-column>
				    <el-table-column
				      label="角色名称"
				      width="180">
				      <template slot-scope="scope">{{ scope.row.name }}</template>
				    </el-table-column>
				    <el-table-column
				      prop="remark"
				      label="角色描述">
				    </el-table-column>
				</el-table>
                 <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="roleSubmitEvt">提 交</el-button>
                </span>
            </template>
        </el-dialog>
        <!--密码弹出框-->
        <el-dialog
            title="重置密码"
            :visible.sync="dialogPassword"
            width="38%">
            <template>
                <el-form ref="permisPassword" size="medium " :model="permisPassword" label-width="70px">
                    <el-form-item  label="旧密码">
                        <el-input show-password v-model="permisPassword.lodpwd" placeholder="请输入旧密码"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码">
                        <el-input show-password v-model="permisPassword.newpwd" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item  label="确认密码">
                        <el-input show-password v-model="permisPassword.newpwds" placeholder="请确认密码"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogPassword = false">取 消</el-button>
                <el-button type="primary" @click="PasswordData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Admin",
        data(){
            return{
                tabIndex:0,
                queryform:{},

                dialogAdd:false,//新增弹框
                permisAdd:{},
 
                dialogEdit:false,//编辑弹框
                permisEdit:{},
               
                dialogRole: false, //分配角色

                dialogPassword:false,//重置密码
                permisPassword:{},

                tableData:[],
                roleData: [],
                keywords:'',
               
                pages:1,
                limits:10,
                currenttotal:null
            }
        },
        created(){
            this.postableData(this.keywords)
        },
        methods:{
           //收索
           searchName:function(){
               this.pages=1;
               this.postableData(this.keywords)
           },
           //获取列表数据
           postableData:function(key){
             var _self=this;
                axios.get(this.API.admin.list,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        keywords:key,
                        page:this.pages,
                        limit:this.limits
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _self.tableData=res.data.data.list;
                        _self.currenttotal=res.data.data.pager.recordCount;
                    }
                })
          },

           //新添管理员
           addaDmin:function(){
             this.dialogAdd=true
           },
           //提交新管理员数据
           postAddData:function(){
               var _this=this; 
               if(this.Util.isBlank(this.permisAdd.username)){
                 _this.$message.warning('请输入用户名！');
                 return
               }
               if(this.Util.isBlank(this.permisAdd.account)){
                 _this.$message.warning('请输入账号！');
                 return  
               }
               if(!this.Util.emailVail(this.permisAdd.email)){
                _this.$message.warning('邮件地址不合理！');
                 return 
               }
              /* if(this.Util.isBlank(this.permisAdd.remark)){
                _this.$message.warning('请输入备注！');
                 return 
               }*/
               if(this.Util.isBlank(this.permisAdd.pwd)){
                _this.$message.warning('请输入密码！');
                 return 
               }
               if(this.Util.isBlank(this.permisAdd.pwds)){
                _this.$message.warning('请确认密码！');
                 return 
               }
               if(this.permisAdd.pwd!=this.permisAdd.pwds){
                 _this.$message.warning('密码输入不一致！');
                 return 
               }else{
                 _this.permisAdd.inputPassword=_this.$md5(this.permisAdd.pwd)
               }
               axios({
                    url:this.API.admin.add,
                    method:'post',
                    data:this.permisAdd,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function (res) {
                    if(res.data.success){
                        _this.$message.success(res.data.msg);
                        _this.dialogAdd=false;
                        _this.$refs[permisAdd].resetFields();
                        _this.postableData(_this.keywords);//重新获取列表
                    }
                })
           },
           //分配角色
            handleRoles: function(index, row){
                this.roleData = [];
                this.dialogRole = true;
                this.roleSelectRow = row;
                this.findRoles(row.id);
            },
            findRoles: function(adminId){
    		    var _self =this;
    		    axios({
                    url: _self.API.admin.roleList,
                    methods: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params: {
                        adminId: adminId
                    }
                }).then(function(resp){
    			    if(resp.data){
    				    var rdata = resp.data;
    				    if(rdata.success){
    					    _self.roleData = rdata.data;
    					    _self.$nextTick(function(){
    						    for(var i = 0; i < _self.roleData.length; i ++){
    							    if(_self.roleData[i].ishave == 1){
    					  				_self.$refs.giveRolemultipleTable.toggleRowSelection(_self.roleData[i], true);
    							    }
    						    }
    					    })
    				    }else{
    					  _self.$message.error(rdata.msg);
    				    }
    			    }
    		    })
            },
            handleGiveSelectionChange: function(rows){
                var _self =this;
    		    var selectRoleids = "";
                if(rows.length > 0){
                    selectRoleids = rows[0].id;
                    for(var i = 1; i < rows.length; i ++){
                        selectRoleids += '-' + rows[i].id;
                    }
                    _self.selectRoleids = selectRoleids;
                }
            },
            roleSubmitEvt: function(){
                var _self = this;
                if(!_self.selectRoleids){
                    _self.$message.error("没有选择任何角色");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '保存中，请稍后',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                axios({
                    url: _self.API.admin.roleSet,
                    methods: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params: {
                        adminId: _self.roleSelectRow.id,
                        roleIds: _self.selectRoleids
                    }
                }).then(function(resp){
                    loading.close();
                    if(resp.data){
                        var rdata = resp.data;
                        if(rdata.success){
                            // _self.dialogRole = false; 
                            _self.$message({
                                message: rdata.msg,
                                type: 'success'
                            });
                            _self.postableData(_self.keywords)
                        }else{
                            _self.$message.error(rdata.msg);
                        }
                    }
                }).catch(function(error){
                    loading.close()
                })
            },
            //禁用或者开启
            handleProhibit:function(index,row){
                var _this=this;
                console.log(index,row)
                if(row.state==1){
                    row.state=0;
                }else{
                    row.state=1;
                }
                axios.get(this.API.admin.on,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        state:row.state,
                        id:row.id
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _this.tableData[index].state=res.data.data.state;
                        _this.$message.success(res.data.msg);
                    }else{
                         if(row.state==1){
                                row.state=0;
                            }else{
                                row.state=1;
                            }
                        _this.$message.error(res.data.msg);
                    }
                })
           },
           //密码重置
           handleEditPwd:function(eid){
               this.dialogPassword=true;
               this.eId=eid
           },
            handleDelete:function(index,uid){
                var _self=this;
                this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios({
                        url:this.API.admin.delete,
                        method: 'delete',
                        params:{
                            id:uid,
                        },
                        headers:{
                            token:_self.Util.getjwtToken(),
                        },
                    }).then((res)=>{
                        if(res.data.success){
                            _self.$message.success('删除成功!');
                            _self.tableData.splice(index, 1);
                        }else{
                            _self.$message.error(res.data.msg)
                        }
                    })
                })
            },
           PasswordData:function(){
              if(this.Util.isBlank(this.permisPassword.lodpwd)){
                this.$message.warning('请输入旧密码！');
                 return 
               }
               if(this.Util.isBlank(this.permisPassword.newpwd)){
                 this.$message.warning('请输入新密码！');
                 return   
               }
               if(this.Util.isBlank(this.permisPassword.newpwds)){
                 this.$message.warning('请确认新密码！');
                 return   
               }
               if(this.permisPassword.newpwd!=this.permisPassword.newpwds){
                 this.$message.warning('确认密码不一致！');
                 return  
               }
               this.getEditPwdrow()
           },
           //提交重置密码
           getEditPwdrow:function(){
                var _this=this;
                axios.get(this.API.admin.setPwd,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        oldPwd:_this.$md5(_this.permisPassword.lodpwd),
                        newPwd:_this.$md5(_this.permisPassword.newpwd),
                        id:this.eId
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _this.$message.success(res.data.msg);
                        _this.dialogPassword=false;
                        _this.permisPassword={};
                    }else{
                        _this.$message.error(res.data.msg);
                    }
                })
           },
           //编辑管理员
           handleEditrow:function(index,row){
               this.dialogEdit=true;
               this.tableIndex=index;
               row.userType=row.userType+'';
               this.permisEdit=JSON.parse(JSON.stringify(row));
           },
           //点击编辑提交
           postEditData:function(){
               this.gethandleEditrow()
           },
           gethandleEditrow:function(){
              var _this=this;
              axios({
                    url:this.API.admin.update,
                    method:'post',
                    data:this.permisEdit,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=> {
                    if(res.data.success){
                        _this.$message.success(res.data.msg);
                        _this.dialogEdit=false;
                        _this.$set(_this.tableData,_this.tableIndex,res.data.data)
                        // _this.postableData(_this.keywords);//重新获取列表
                    }
                })
           },

          //分页
           handleSizeChange:function(e){
             this.limits=e;
             this.postableData(this.keywords);//重新获取列表
           },
           handleCurrentChange:function(e){
             this.pages=e;
             this.postableData(this.keywords);//重新获取列表
           },
        }
    }
</script>

<style scoped lang="scss">
.el-input__icon{
   cursor: pointer;
}
.isDivcom{
     color:#000;
     border-bottom: 2px solid #000000;
 }
</style>